//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from "vuex";

export default {
  name: "change-password-card",
  props: {
    userId: 0,
    userName: ''
  },
  data: (vm) => ({
    changePasswordForm: {
      data: {
        newPassword: '',
        confirmPassword: '',
      },
      rules: [(v) => !!v || 'Required'],
      newPasswordRules: [
        (v) => !!v || 'Required',
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Required',
        (v) =>
            (!!v && v === vm.changePasswordForm.data.newPassword) ||
            'Passwords must match',
      ],
    },

  }),
  methods: {
    ...mapActions('common/userProfile', [
      'changePassword',
    ]),
    async save() {
      if (
          this.$refs.changePasswordForm.validate() &&
          this.changePasswordForm.data.newPassword
      ) {
        this.changePasswordForm.data.userId = this.userId
        const response = await this.$axios.$post('/User/must-change-password', this.changePasswordForm.data)
        if (
            response &&
            response.apiMessages.hasErrors &&
            response.apiMessages.validationErrors
        ) {
          this.$toast.error(response.apiMessages.validationErrors.error)
        } else if (response) {
          this.$toast.success('Password successfully changed!')
          this.$emit('passwordChanged', {
            username: this.userName,
            password: this.changePasswordForm.data.newPassword,
          })
        }
      } 
    },
  }
}
