//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LabeledControlLayout from "../../../../../MultiSiteClient/components/shared/labeled-control-layout";
import {frontends} from "../../../../../MultiSiteClient/enums/frontends";

export default {
  name: "forgot-password-card",
  components: {
    LabeledControlLayout,
  },
  data() {
    return {
      forgotPasswordForm: {
        data: {
          email: '',
        },
        emailRules: [
          (v) => !!v || 'This is a required field',
          (v) =>
              /.+@.+\..+/.test(v) ||
              this.$t('Please enter a valid email address')
        ],
      },
        emailExistsText: '',
    }
  },
  methods: {
    async forgotPassword() {
      if(!this.$refs.forgotPasswordForm.validate()) return;
      if(await this.checkIfEmailExists() === false) {
        this.emailExistsText = " No user is associated with this email address. ";
        return
      }
        this.$axios.$post('auth/forgotPassword', {
          Email: this.forgotPasswordForm.data.email,
          FrontendID: frontends.CP,
        })
          .then((response) => {
            if(response.apiMessages.hasErrors && response.apiMessages.serverErrors && response.apiMessages.serverErrors.length > 0) {
              response.apiMessages.serverErrors.map((serverError) => {
                this.$toast.error(serverError);
              });
              return;
            }
            if(response.apiMessages.successMessages && response.apiMessages.successMessages.length > 0) {
              response.apiMessages.successMessages.map((successMessage) => {
                this.$toast.success(successMessage);
              });
            }
            else {
              this.$toast.success("Successfully reset password!");
            }
            this.$emit('cancelForgotPassword');
          })
          .catch((error) => {
            this.$toast.error("Error while resetting password!");
          });
    },
    async checkIfEmailExists() {
      let emailExistsResponse = await this.$axios.$get('User/isEmailAvailable/'+this.forgotPasswordForm.data.email);
      if(emailExistsResponse.hasErrors && 
          emailExistsResponse.errorMessages && 
          emailExistsResponse.errorMessages.length > 0) {
        emailExistsResponse.errorMessages.map((errorMessage) => {
          this.$toast.error(errorMessage);
        });
        return false;
      }
      return emailExistsResponse.value;
    },
  }
}
